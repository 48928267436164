import React from "react"
import Layout from "../components/shared/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import ModalForm from "../components/modal-form"
import "../components/styles/jobs-details.scss"

export const query = graphql`
  query MyQuery($uid: String!, $lang: String!) {
    prismic {
      job(lang: $lang, uid: $uid) {
        auteur
        description
        image_de_l_emploi
        image_de_l_employeur
        title
        requirements
        address
        city
        province
        temps_de_travail
        type_de_salaire
        type_de_travail
        date_de_publication
        description
        salaire
        _meta {
          uid
          type
          lang
        }
      }
    }
  }
`

const JobsDetails = ({ data }) => {
  const [modalShow, setModalShow] = React.useState(false)
  let job = data.prismic.job
  let jobTitle = job.title
  let jobDescription = job.description
  let jobImageURL = job.image_de_l_emploi.url
  let jobAuthorName = job.auteur
  let address = job.address
  let location = `${job.city}, ${job.province}`
  let authorImageURL = job.image_de_l_employeur.url
  let requirements = job.requirements
  let langFr = job._meta.lang === "fr-ca"
  let jobType = job.type_de_travail
  let salary =
    jobType !== "Stage non rémunéré" && job.salaire
      ? `${job.salaire}$ /${
          job.type_de_salaire === "Taux Horaire" ? "h" : "semaine"
        }`
      : null

  let shift = job.temps_de_travail
  let googleAddress = `${address} ${location}`
  let googleAddressURI = encodeURI(googleAddress);
  let encodedLocation = encodeURIComponent(googleAddress.trim())
  console.log(encodedLocation)
  let detailContent = (
    <section className="details">
      <div className="details__image--container">
        <img src={jobImageURL} alt="Job company logo"></img>
        <div className="details__map">
          <iframe
          title="jobLocation"
            frameBorder="0" style={{border: 0}}
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDirh5QbPvGA3wD4ZoUXIn4xifzTSZuDvg&q=${googleAddressURI}`} allowfullscreen>
          </iframe>
        </div>
      </div>
      <div className="details__text--container">
        <h1>{jobTitle}</h1>
        <div>
          <div className="details__author">
            <img src={authorImageURL} alt="author"></img>
            <div className="details__company-info--container">
              <div className="details__company-info">
                <p>{jobAuthorName}</p>
                <p>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${googleAddress}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {address}, {location}
                  </a>
                </p>
              </div>
              <div className="details__job-specifics">
                <p>{salary}</p>
                <p>
                  {jobType}, {shift}
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div className="details__description">
          <RichText render={jobDescription} />
        </div>
        {requirements && <p className="details__requirements">Requirements</p>}
        {requirements && (
          <div className="details__requirements--list">
            <RichText render={requirements} />
          </div>
        )}
        <button onClick={() => setModalShow(true)}>Apply Now</button>
      </div>
      <ModalForm
        french={langFr}
        jobTitle={jobTitle}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </section>
  )

  return (
    <Layout french={langFr}>
      <SEO title="Jobs Details" />
      {detailContent}
    </Layout>
  )
}

export default JobsDetails
