import React from "react"
import SingleJobForm from "../components/singleJobForm"
import { Modal } from "react-bootstrap"

const ModalForm = ({ french, jobTitle, show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{jobTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SingleJobForm jobTitle={jobTitle} french={french} />
      </Modal.Body>
    </Modal>
  )
}

export default ModalForm
